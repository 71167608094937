@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
// @import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
// body {
//   font-family: 'Nanum Gothic', sans-serif;
// }
@import "src/css/quasar.variables";

.btn-1 {
  font-weight: bold;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c9c9c9;
  max-width: 144px;
  width: 100%;
  height: 55px;
}

.btn-2 {
  font-weight: bold;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #c9c9c9;
  max-width: 130px;
  width: 100%;
  height: 40px;
}

.btn-drop {
  color: red;
  border-color: red;
}

.btn-drop-cancel {
  color: black;
  border-color: black;
}

.btn-drop-confirm {
  background-color: red;
  color: white;
  border-color: red;
}

.btn-modify {
  color: $primary;
  border-color: $primary;
  //margin-right: 200px;
  //margin-left: 25%;
}

.btn-modify-confirm {
  background-color: $primary;
  color: white;
  border-color: $primary;
  //margin-right: 200px;
}

.btn-cancel {
  color: black;
  border-color: black;
}

.hover-title {
  font-size: large;
  font-weight: bold;
}

.hover-content {
  font-size: large;
  margin-bottom: 20px;
}

.hover-content-last {
  font-size: large;
}

.color-enter {
  color: $enter;
}

.color-leave {
  color: $leave;
}

.color-complete {
  color: $complete;
}

.color-drop {
  color: $drop;
}

.bg-color-enter {
  background-color: $enter;
}

.bg-color-leave {
  background-color: $leave;
}

.bg-color-complete {
  background-color: $complete;
}

.bg-color-drop {
  background-color: $drop;
}

.bg-color-audio {
  background-color: #eff1f2;
}

.color-audio {
  color: orange;
}

.text-h7 {
  font-size: 16px;
}

.q-tab__label {
  font-size: 18px;
}

.patient-info-calendar {
  max-width: 180px;
  max-height: 1px;
}

.patient-info-input {
  max-width: 80px;
  max-height: 1px;
}

.social-style {
  background-color: white;
  color: black;
  font-size: 13px;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 75px;
  text-align: center;
  margin-right: 10px;
  font-size: 12px;
  vertical-align: middle;
  line-height: 150px;
}

.my-sticky-header-table {
  /* height or max-height is important */
  height: 310px;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    /* bg color is important for th; just specify one */
    background-color: #ffffffff;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  } /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
  } /* prevent scrolling behind sticky top row on focus */
  tbody {
    /* height of all previous header rows */
    scroll-margin-top: 48px;
  }
}

.enroll-1 {
  background-color: #F4F4F4;
}

.enroll-border {
  border-top-color: #E0E0E0;
  border-top-width: 1px;
  border-top-style: solid;
}

.enroll-border-last {
  border-bottom-color: #E0E0E0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
